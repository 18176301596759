.everything {
  text-align: center;
  background-color: #0e171b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c7d1c9;
}

.email-link {
  color: #c7d1c9;
}
.email-link:hover {
  color: #a5b6a9;
}
